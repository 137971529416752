export { default as tblogo } from "./tblogo.png";
export { default as tblogoName } from "./tbLogoName.png";
export { default as brandPlacehoder } from "./brand-image.png"

export { default as banner } from "./BannerImage.png";
export { default as AboutUs_Banner } from "./AboutUs_Banner.png";
export { default as TechBazaarImage } from "./TechBazaar.png";
export { default as storeLogo } from "./storelogo.jpg";
export { default as MobilePic } from "./mobileimage.svg";
export { default as tabletImage } from "./tabletImage.svg";
export { default as laptop } from "./laptop.svg";
export { default as headPhoneImage } from "./headPhoneImage.svg";
export { default as TV } from "./TV.svg";
export { default as desktopImage } from "./desktopImage.svg";
export { default as ultracodesLogo } from "./ultrcodesLogo.svg";
export { default as videoIcon } from "./videoIcon.png";
export { default as Banner } from './1.webp'
export { default as Banner2 } from './22.webp'

export { default as Banner4 } from "./1015 x200w.webp";
export { default as Banner3 } from "./1015 x200w2.webp";

